import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HelloAutoAppBar } from "../components/app_bar_helloauto";
import money from "../assets/images/tirada/money-mouth.png";
import billete from "../assets/images/tirada/money.png";
import "./tirada.css";
import { useTranslation } from "react-i18next";

export const TiradaGratisHelloAuto5 = () => {
  const history = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  let data = location.state.data;

  function handleClickNext() {
    // Navegación nativa a la url de data
    window.navigation.postMessage("web," + data + ",,,");
    window.navigation.postMessage("native,,,pop,");
  }

  function onBackClick() {
    window.navigation.postMessage("native,,,pop,");
  }

  function handleClickBefore() {
    history(-1);
  }
  return (
    <>
      <HelloAutoAppBar
        title="Promo Hello Auto"
        onBackClick={onBackClick}
        backgroundColor="#018bed"
      ></HelloAutoAppBar>
      <div id="ha-clipped-container" className="th-clipped-container-ha">
        {" "}
        &nbsp;
      </div>
      <div className="th-parent-ha">
        <div className="th-icon-box-ha5">
          <img src={money} className="th-icon-ha5" alt="money" />
          <div className="th-icon-box-ha-text5">
            <span className="silka-regular">
              {t("promotirada.tirada5.cuantopuedo")}{" "}
            </span>

            <span className="silka-black">
              {" "}
              {t("promotirada.tirada5.ahorrar")}{" "}
            </span>
            <span className="silka-regular">
              {" "}
              {t("promotirada.tirada5.con")} Hello Auto{" "}
            </span>
            <span className="silka-regular">? </span>
          </div>
        </div>
        <div className="silka-light th-box-text-ha5">
          <span className="silka-regular">
            {t("promotirada.tirada5.sabemosquete")}{" "}
          </span>
          <span className="silka-bold">
            {t("promotirada.tirada5.gusta ahorrar")}{" "}
          </span>
          <span className="silka-regular">
            {t("promotirada.tirada5.yque")}{" "}
          </span>
          <span className="silka-bold">
            {t("promotirada.tirada5.conducesmejorquenadie")}
          </span>
          <br></br>
          <span className="silka-regular">
            {t(
              "promotirada.tirada5.enhelloautopuedestraertusegurodecochedesde"
            )}
          </span>
        </div>
        <div className="th-div-icon-on-parent5">
          <img src={billete} className="th-img-hello-pump" alt="Gasolina" />
          <div className="th-div-icon-on5">
            <div className="th-text-tirada5-container">
              <span className="silka-black th-treintae">9,99€ </span>

              <span className="silka-regular th-texto-containerblue">
                {t("promotirada.tirada5.almes")}
              </span>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="silka-light " style={{ margin: "5vw" }}>
          <span className="silka-regular" style={{ fontSize: "2.5vh" }}>
            {t(
              "promotirada.tirada5.calculaahoratuseguroydescubrecualestuprecio"
            )}{" "}
          </span>
        </div>
      </div>
      <div className="th-row-button-ha-parent">
        <div className="th-row-buttons-ha">
          <div
            className="th-boton-siguiente-ha2 silka-bold"
            onClick={handleClickBefore}
          >
            {t("promotirada.tirada1.atras")}
          </div>
          <div
            className="th-boton-siguiente-ha1 silka-bold"
            onClick={handleClickNext}
          >
            {t("promotirada.tirada5.calcular")}
          </div>
        </div>
      </div>
    </>
  );
};
